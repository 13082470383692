export const handleLogout = async () => {
  const url = window.location.origin;
  localStorage.clear();
  window.open(
    `${process.env.REACT_APP_LOGIN_UPBOND_DOMAIN}/v2/logout?client_id=${
      process.env.REACT_APP_OTP_CLIENT_ID
    }&returnTo=${encodeURIComponent(url)}&direct_logout=true`,
    "_self"
  );
};
