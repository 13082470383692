import { Dialog, Transition } from "@headlessui/react";
import { WITHDRAW_MEMBER } from "services/users";
import { toast } from "react-hot-toast";
import { Fragment } from "react";
import useHandleLogout from "hooks/useHandleLogout";
import { useTranslation } from "react-i18next";

const PopupCancelMembership = (props) => {
  const { open, handleClose } = props;
  const handleLogout = useHandleLogout();
  const { t } = useTranslation();

  const handleCancel = () => {
    WITHDRAW_MEMBER({})
      .then((res) => {
        // Navigate to force logout 3.0 when the withdraw is succecced and get the reponse data from api 
        if (res.data) {
          handleLogout();
        }
      })
      .catch((error) => {
        toast.error(error.data || "Some error occured");
        if (typeof error === "object") {
          console.error("Withdrawn account", JSON.stringify(error));
        } else {
          console.error("Withdrawn account", error);
        }
      });
  };
  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-mobile transform overflow-hidden rounded-2xl left align-middle shadow-xl text-left transition-all">
                  <div className="flex flex-col px-6 py-4 pb-8 rounded-3xl bg-primary-100 max-w-[327px]">
                    <div className="flex flex-col self-stretch px-4 py-3 my-4 bg-white rounded-lg border border-solid border-zinc-300 text-black max-h-card overflow-y-scroll overflow-x-hidden">
                      <div className="mb-4">
                        <div className="leading-10 text-center font-semibold">
                          {t("cancel_title")}
                        </div>
                        <div className="self-start mt-2 text-xs font-normal grid gap-3">
                          <p>{t("cancel_content_desc1")}</p>
                          <ul className="list-disc pl-5 grid gap-3">
                            <li>{t("cancel_content_desc2")}</li>
                            <li>{t("cancel_content_desc3")}</li>
                            <li>{t("cancel_content_desc4")}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="font-primary mt-2">
                      <div className="flex flex-col text-sm font-semibold leading-5 text-center text-black">
                        {t("cancel_confirmation")}
                      </div>
                    </div>
                    <div className="flex gap-8">
                      <div className="frame h-9" onClick={handleClose}>
                        <div className="text-wrapper font-hankyu font-bold">
                          Back
                        </div>
                      </div>

                      <div className="frame h-9" onClick={handleCancel}>
                        <div className="text-wrapper font-hankyu font-bold">
                          Yes
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PopupCancelMembership;
