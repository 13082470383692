/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Konva from "konva";
import moment from "moment";
import { useSelector } from "react-redux";
import couponImage from "assets/images/coupon-sm-expired.png";
import Layout from "pages/Layout";
import UserInfo from "../component/UserInfo";

const StampComponent = () => {
  const memberInfo = useSelector((state) => state.member.memberInfo);
  const [expiredDate, setExpiredDate] = useState();
  const [isAndroid, setIsAndroid] = useState(false);
  let observer; // Define observer variable

  useEffect(() => {
    // checking user agent device & browser
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setIsAndroid(true);
    }

    if (memberInfo?.expire_date) {
      const parsedDate = moment(memberInfo?.expire_date, "YYYY-MM-DD HH:mm:ss");
      if (parsedDate.isValid()) {
        setExpiredDate(parsedDate.format("DD/MM/YYYY"));
      }
    }

    // Function to load Digishot.js
    const loadDigishotFunc = () => {
      // Check if script is already present
      // get script from folder public/static/js/Digishot.js
      if (!document.querySelector('script[src="./static/js/Digishot.js"]')) {
        const script = document.createElement("script");
        script.src = "./static/js/Digishot.js";
        script.async = true;
        script.onload = () => {
          console.log("Digishot.js loaded.");
        };
        document.body.appendChild(script);
      } else {
        console.log("Digishot.js already loaded.");
      }
    };

    // Check if shotview-container is ready
    const shotviewContainer = document.getElementById("shotview-container");
    if (shotviewContainer) {
      // If shotview-container is already in DOM, load Digishot.js immediately
      loadDigishotFunc();
    } else {
      // If shotview-container is not ready, wait for it to be added to DOM
      observer = new MutationObserver((mutationsList, observer) => {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
            const addedNode = mutation.addedNodes[0];
            if (addedNode.id === "shotview-container") {
              observer.disconnect();
              loadDigishotFunc();
              break;
            }
          }
        }
      });
      observer.observe(document.body, { childList: true, subtree: true });
    }
    // Cleanup function
    return () => {
      // Clean up observer if it's still running
      if (observer) {
        observer.disconnect();
      }
    };
  }, [memberInfo]); // Add memberInfo to dependencies if needed

  useEffect(() => {
    // Initialize Konva.Stage after shotview-container is ready
    const stage = new Konva.Stage({
      container: "shotview-container",
      width: window.innerWidth,
      height: window.innerHeight,
    });

    // Example usage of stage (add shapes, layers, etc.)
    const layer = new Konva.Layer();
    const rect = new Konva.Rect({
      x: 20,
      y: 20,
      width: 100,
      height: 50,
      fill: "white",
      draggable: true,
    });
    layer.add(rect);
    stage.add(layer);

    return () => {
      // Clean up stage if needed
      layer.destroy();
      stage.destroy();
    };
  }, []);
  useEffect(() => {
    const shotviewContainer = document.getElementById("shotview-container");

    if (shotviewContainer) {
      let startY;
      let lastMoveY;
      let isScrolling = false;

      const handleTouchStart = (event) => {
        startY = event.touches[0].clientY;
        lastMoveY = startY;
        isScrolling = true;
      };

      const handleTouchMove = (event) => {
        if (!isScrolling) return;
        const moveY = event.touches[0].clientY;
        const deltaY = lastMoveY - moveY;
        lastMoveY = moveY;
        window.scrollBy({
          top: deltaY,
          behavior: "auto", // Use "auto" for immediate effect, we will handle smoothness manually
        });
      };

      const handleTouchEnd = () => {
        isScrolling = false;
      };

      const animateScroll = () => {
        if (isScrolling) {
          requestAnimationFrame(animateScroll);
        }
      };

      shotviewContainer.addEventListener("touchstart", handleTouchStart);
      shotviewContainer.addEventListener("touchmove", handleTouchMove);
      shotviewContainer.addEventListener("touchend", handleTouchEnd);
      requestAnimationFrame(animateScroll);

      return () => {
        shotviewContainer.removeEventListener("touchstart", handleTouchStart);
        shotviewContainer.removeEventListener("touchmove", handleTouchMove);
        shotviewContainer.removeEventListener("touchend", handleTouchEnd);
      };
    }
  }, []);


  return (
    <Layout title="GUEST COUPON" isShowPanel={false} column={2}>
      <div className="relative z-10">
        <img loading="lazy" srcSet={couponImage} alt="coupon" />
        <span className="absolute bottom-4 left-4 text-[8px] font-semibold">
          {expiredDate}
        </span>
      </div>
      <div
        id="shotview-container"
        className={`${isAndroid ? "-mt-28" : "-mt-[4rem]"}`}
      ></div>
      <div className={`${isAndroid ? "-mt-24" : "-mt-[4rem]"} z-50`}>
        <UserInfo />
      </div>
    </Layout>
  );
};

export default StampComponent;
