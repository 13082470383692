/* eslint-disable react/jsx-no-comment-textnodes */
import MemberAgreement from "component/MemberAgreement";
import couponImage from "assets/images/coupon-sm.png";
import Layout from "./Layout";
import { useSelector } from "react-redux";
import moment from "moment";
import { useState } from "react";

const Home = () => {
  const memberInfo = useSelector((state) => state.member.memberInfo);
  const [content, setContent] = useState(false);

  const handleChangePage = () => {
    // while router page doesn't change content will not be shown 
    setContent(true);
  };

  return (
    <Layout title="Guest Coupon" onChangePage={handleChangePage}>
      {content && (
        <>
          <div className="relative">
            <img
              loading="lazy"
              srcSet={couponImage}
              className="mt-4"
              alt="coupon"
            />
            <span className="absolute bottom-4 left-4 text-[8px] font-semibold">
              {memberInfo?.expired_date &&
                moment(memberInfo?.expired_date, "DD/MM/YYYY")}
            </span>
          </div>
          <MemberAgreement isCoupon={true} />
        </>
      )}
    </Layout>
  );
};

export default Home;
