import { useEffect, useState } from "react";
import CouponAgreementEnglish from "./CouponAgreementEnglish";
import CouponAgreementKorean from "./CouponAgreementKorean";
const ContentAgreement = () => {
  const [language, setlanguage] = useState("en");

  useEffect(() => {
    if (localStorage.getItem("lang")) {
      setlanguage(localStorage.getItem("lang"));
    }
  }, [localStorage.getItem("lang")]);

  return (
    <>
      <div className="flex flex-col self-stretch mx-auto w-full text-sm font-semibold leading-5 max-w-[360px] text-neutral-700">
        {language === "en" ? (
          <CouponAgreementEnglish />
        ) : (
          <CouponAgreementKorean />
        )}
      </div>
    </>
  );
};

export default ContentAgreement;
