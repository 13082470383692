import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";
import UserInfo from "./UserInfo";

const GuestCoupon = (props) => {
  const { type } = props;
  const vipInfo = useSelector((state) => state.vip.vipInfo);
  const [isVipMember, setIsVipMember] = useState();
  const memberInfo = useSelector((state) => state.member.memberInfo);
  const { t } = useTranslation();
  const [barcode, setBarcode] = useState("");

  useEffect(() => {
    if (memberInfo.coupon_no) {
      setBarcode(memberInfo?.coupon_no.replace(/-/g, ""));
    }
  }, [memberInfo]);

  useEffect(() => {
    if (vipInfo?.isVip) {
      setIsVipMember(vipInfo?.isVip);
      setTimeout(() => {
        window.open(process.env.REACT_APP_BASE_VIP_URL, "_self");
      }, 5000);
    }
  }, [vipInfo]);

  return (
    <>
      {isVipMember ? (
        <div className="text-sm font-semibold leading-5 text-center text-red-500 max-w-[262px] mt-6">
          {t("vip_member_access")}
        </div>
      ) : (
        <>
          <Barcode
            format="codabar"
            value={barcode}
            width={2.2}
            displayValue={false}
          />
          <div className="text-sm">{memberInfo?.coupon_no}</div>
        </>
      )}
      <UserInfo />
    </>
  );
};

export default GuestCoupon;