/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect, useState } from "react";
import Layout from "./Layout";
import GuestCoupon from "component/GuestCoupon";
import couponImage from "assets/images/coupon-sm.png";
import { useSelector } from "react-redux";
import moment from "moment";

const CouponDetail = () => {
  const memberInfo = useSelector((state) => state.member.memberInfo);
  const [expiredDate, setExpiredDate] = useState();

  useEffect(() => {
    if (memberInfo?.expire_date) {
      const parsedDate = moment(memberInfo?.expire_date, "YYYY-MM-DD HH:mm:ss");
      if (parsedDate.isValid()) {
        // set expiry date to display expiry label on the top of banner image
        setExpiredDate(parsedDate.format("DD/MM/YYYY"));
      }
    }
  }, [memberInfo]);

  return (
    <Layout title="GUEST COUPON" isShowUserInfo={true}>
      <div className="relative">
        <img
          loading="lazy"
          srcSet={couponImage}
          alt="coupon"
          className="mt-4"
        />
        <span className="absolute bottom-4 left-4 text-[8px] font-semibold">
          {expiredDate}
        </span>
      </div>
      <GuestCoupon />
    </Layout>
  );
};

export default CouponDetail;
