import React, { useCallback, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "pages/Home";
import CouponDetail from "pages/CouponDetail";
import StampComponent from "pages/Stamp";
import { Provider } from "react-redux";
import store from "store";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import couponImage from "assets/images/coupon-sm.png";
import "styles/globals.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/user-info",
    element: <Home />,
  },
  {
    path: "/coupon-detail",
    element: <CouponDetail />,
  },
  {
    path: "/stamp",
    element: <StampComponent />,
  },
]);

const RootProvider = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("__token__")
  );
  const [isLoading, setIsLoading] = useState(true); // State to control loading
  const [showLogin, setShowLogin] = useState(false); // State to control login UI visibility

  const getUserInfo = useCallback(async () => {
    const code = window?.location?.href?.includes("code"); // Get code from query param URL
    try {
      setIsLoading(true); // Show loading state
      const token = await getAccessTokenSilently({ detailedResponse: true }); // Get access token from auth0
      if (token.id_token) {
        localStorage.setItem("__token__", token.id_token);
        Sentry.setUser({ id: token.id_token, username: token.id_token }); // Set sentry user info
        setAccessToken(token.id_token);
      } else if (!code) { // If URL doesn't have a code query param then show the login UI
        setShowLogin(true); // Show custom login UI if no token found
      }
    } catch (err) {
      console.error("Error getting token silently:", err);
      if (!code) { // If URL doesn't have a code query param then show the login UI
        if (err?.error === "missing_refresh_token") { // Only error missing_refresh_token will be auto redirected to the login
          loginWithRedirect();
          // setShowLogin(true); // Show custom login UI on error
        } else { // Others errors such as timeout while get token etc will be shown the landing page
          setShowLogin(true); // Show custom login UI on error
        }
      }
    } finally {
      setIsLoading(false); // Ensure loading stops after token check
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!accessToken) { // If token not exists will try to get token etc
      getUserInfo();
    } else {
      setIsLoading(false); // If token exists, stop loading
    }
  }, [getUserInfo, accessToken]);

  // Show Loading UI
  if (isLoading) {
    return (
      <div className="w-full h-screen flex flex-col justify-center">
        <div aria-label="Loading..." role="status" className="mx-auto">
          <svg
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 animate-spin stroke-slate-500"
          >
            <path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12"></path>
          </svg>
        </div>
      </div>
    );
  }

  // Show Landing page UI
  if (showLogin && !isLoading) {
    return (
      <div className="flex flex-col items-center gap-32 font-semibold w-full lg:max-w-mobile min-h-screen m-auto relative">
        <img className="w-full h-52" loading="lazy" srcSet={couponImage} alt="coupon" />
        <div className="px-4 w-full flex">
          <button
            className="bg-black rounded-full p-2 text-white text-base w-1/2 mx-auto"
            onClick={() => loginWithRedirect()}
          >
            Back to Login
          </button>
        </div>
      </div>
    );
  }

  // Get router page
  return (
    <Provider store={store}>
      {accessToken && <RouterProvider router={router} />}
    </Provider>
  );
};

export default RootProvider;
