// reducers/index.js
import { combineReducers } from "redux";
import counterReducer from "./counter/counterSlice";
import memberReducer from "./member/memberSlice";
import vipReducer from "./member/vipSlice";
import invalidMemberReducer from "./member/invalidMemberSlice";
import authReducer from "./auth/authSlice";

const rootReducer = combineReducers({
  counter: counterReducer,
  member: memberReducer,
  invalidMember: invalidMemberReducer,
  auth: authReducer,
  vip: vipReducer,
  // Other reducers can be added here.
});

export default rootReducer;
