// memberSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  memberInfo: {
    coupon_no: null, 
    first_name: null,
    middle_name: null,
    last_name: null,
    birthdate: null,
    day: null,
    month: null,
    year: null,
    email: null,
    expire_date: null,
    newsletter: false
  },
};

const memberSlice = createSlice({
  name: "member",
  initialState, // Use the initialState defined above
  reducers: {
    setMemberInfo: (state, action) => {
      state.memberInfo = action.payload;
    },
  },
});

export const { setMemberInfo } = memberSlice.actions;
export default memberSlice.reducer;
