import {
  ACTION_GET,
  ACTION_PATCH,
  ACTION_POST,
  ACTION_PUT,
  BASE_URL,
} from "./config";
import { handleLogout } from "helpers/logout";

export const GET_DETAIL = () => {
  const url = `${BASE_URL}/coupon/user/detail`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        handleError(error, reject);
      });
  });
};

export const GET_DETAIL_CARD = () => {
  const url = `${BASE_URL}/coupon/usercard/detail`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        handleError(error, reject);
      });
  });
};
export const GET_EXPIRED_COUPON = (params) => {
  const url = `${BASE_URL}/coupon/update/coupon/expiredate`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        handleError(error, reject);
      });
  });
};

export const GET_USER_INFO = () => {  
  const url = `${BASE_URL}/userinfo`
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const POST_USER_INFO = () => {  
  const url = `${BASE_URL}/userinfo`
  return new Promise((resolve, reject) => {
    ACTION_POST(url, null)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const VIP_REGISTER_EMAIL = (params) => {
  const url = `${BASE_URL}/coupon/email/registeration`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        handleError(error, reject);
      });
  });
};
export const VIP_USER_REGISTER = (params) => {
  const url = `${BASE_URL}/coupon/user/registeration`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        handleError(error, reject);
      });
  });
};

export const VIP_REGISTER_NAME = (params) => {
  const url = `${BASE_URL}/coupon/name/registeration`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        handleError(error, reject);
      });
  });
};
export const WITHDRAW_MEMBER = (params) => {  
  const url = `${BASE_URL}/coupon/user/withdraw`
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const SEND_EMAIL = (params) => {
  const url = `${BASE_URL}/send/email`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        handleError(error, reject);
      });
  });
};
export const VERIFY_EMAIL = (params) => {
  const url = `${BASE_URL}/verify/email`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        handleError(error, reject);
      });
  });
};

const handleError = async (err, reject) => {  
  if (err?.code === 401) {
    await handleLogout();
  } else {
    reject(err);
  }
};
