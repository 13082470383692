// vipSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vipInfo: {
    isVip: false,
  },
};

const vipSlice = createSlice({
  name: "vip",
  initialState, // Use the initialState defined above
  reducers: {
    setVipInfo: (state, action) => {
      state.vipInfo = action.payload;
    },
  },
});

export const { setVipInfo } = vipSlice.actions;
export default vipSlice.reducer;
