import { Disclosure, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import arrowDown from "assets/images/arrow-down.svg";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";

const UserInfo = (props) => {
  const memberInfo = useSelector((state) => state.member.memberInfo);
  const [isCouponExpired, setIsCouponExpired] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const now = moment();
    // Checking coupon's user is expired or not, to display the expiry text message
    if (memberInfo?.expire_date) {
      if (now.isAfter(moment(memberInfo?.expire_date))) {
        setIsCouponExpired(true);
      } else {
        setIsCouponExpired(false);
      }
    }
  }, [memberInfo]);

  return (
    <>
      {isCouponExpired && (
        <div className="text-sm text-center mb-4 text-red-500 font-semibold">
          {t("coupon_expired")}
        </div>
      )}
      <div className="mt-2 text-sm text-neutral-700 text-center">
        {t("expired_date")}：{memberInfo?.expire_date ?? "-"}
      </div>
      <div className="text-2xl text-neutral-700 text-center">
        {memberInfo?.first_name} {memberInfo?.middle_name ? memberInfo?.middle_name+' '+memberInfo?.last_name : memberInfo?.last_name }
      </div>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-center gap-2.5 text-sm font-normal px-5 mt-6">
              {t("about_coupon")}
              <img
                alt="coupon"
                loading="lazy"
                src={arrowDown}
                className="my-auto w-3.5 aspect-[1.41] fill-neutral-700"
              />
            </Disclosure.Button>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel>
                <div className="mt-2 text-sm font-light leading-5 px-5 py-2.5">
                  {t("items_departments")}
                  <br />
                  {t("items_description")}
                  <br />
                  {t("present_coupon")}
                  <br />
                  {t("coupon_cannot_used")}
                  <br />
                  {t("coupon_description")}
                  <br />
                  <br />
                  <span className="font-semibold">
                    Stores where the discount coupon can be used
                  </span>
                  <br />
                  Hankyu Umeda Main Store
                  <br />
                  Hanshin Umeda Main Store
                  <br />
                  Hankyu Men's Osaka
                  <br />
                  Hankyu Men's Tokyo
                  <br />
                  Hakata Hankyu
                  <br />
                  Kobe Hankyu
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      <div
        onClick={() =>
          window.open(
            "https://website.hankyu-dept.co.jp/fl/taxrefund/index.html",
            "_blank"
          )
        }
        className="cursor-pointer mt-2 text-sm font-light text-center text-black underline whitespace-nowrap"
      >
        {t("tax_exemption")}
      </div>
    </>
  );
};

export default UserInfo;
